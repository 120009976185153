<template>
  <component
    :is="tag"
    v-on="$listeners"
  >
    <div class="chat-info flex-grow-1">
      <h5 class="mb-0">
        {{ user[0].data.title }}
      </h5>
      <p class="card-text text-truncate">
        {{ user[0].data.description }}
      </p>
    </div>
    <div
      v-if="isChatContact"
      class="chat-meta text-nowrap"
    >
      <!-- <small class="float-right mb-25 chat-time">{{ formatDateToMonthShort(user[2].updated_at, { hour: 'numeric', minute: 'numeric' }) }}</small> -->
      <b-badge
        v-if="user[0].unread_count != 0"
        pill
        variant="primary"
        class="float-right"
      >
        {{ user[0].unread_count }}
      </b-badge>
    </div>
  </component>
</template>

<script>
import { BAvatar, BBadge } from 'bootstrap-vue'
import { formatDateToMonthShort } from '@core/utils/filter'
import useChat from './useChat'

export default {
  components: {
    BAvatar,
    BBadge,
  },
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    user: {
      type: Array,
      required: true,
    },
    isChatContact: {
      type: Boolean,
      dedfault: false,
    },
  },
  setup() {
    const { resolveAvatarBadgeVariant } = useChat()
    return { formatDateToMonthShort, resolveAvatarBadgeVariant }
  },
}
</script>

<style>

</style>
