<template>
  <div class="chats">
    <div
      v-for="(msgGrp, index) in formattedChatData.formattedChatLog"
      :key="msgGrp.senderId+String(index)"
      class="chat"
      :class="{'chat-left': msgGrp.sender === formattedChatData.user.id}"
    >
      <div class="chat-avatar" v-for="contact in formattedChatData.contact" :key="contact.id">
        <b-avatar
          v-if="msgGrp.sender === contact.id"
          size="36"
          class="avatar-border-2 box-shadow-1"
          variant="transparent"
          :src="contact.avatar"
        />
      </div>
      <div class="chat-body">
        <div
          v-for="msgData in msgGrp.messages"
          :key="msgData.time"
          class="chat-content"
        >
          <p>{{ msgData.msg }}</p>
          <small class="float-right chat-time">{{ msgData.sender.first_name }} {{ msgData.sender.last_name }}, {{ formatDateToMonthShort(msgData.time, { hour: 'numeric', minute: 'numeric' }) }}</small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import { BAvatar } from 'bootstrap-vue'
import { formatDateToMonthShort } from '@core/utils/filter'

export default {
  components: {
    BAvatar,
  },
  props: {
    chatData: {
      type: Object,
      required: true,
    },
    profileUserAvatar: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const formattedChatData = computed(() => {
      const contact = props.chatData.contacts

      const user = {
        id: props.chatData.user.id,
        avatar: props.chatData.user.avatar,
        email: props.chatData.user.email
      }

      let chatLog = []
      if (props.chatData.data) {
        chatLog = props.chatData.data
      }

      const formattedChatLog = []
      let chatMessageSenderId = chatLog[0] ? chatLog[0].sender.id : undefined
      let msgGroup = {
        sender: chatMessageSenderId,
        messages: [],
      }

      // console.log(props.chatData)

      chatLog.forEach((msg, index) => {
        if (chatMessageSenderId === msg.sender.id) {
          msgGroup.messages.push({
            msg: msg.body,
            time: msg.updated_at,
            sender: msg.sender
          })
        } else {
          chatMessageSenderId = msg.sender.id
          
          formattedChatLog.push(msgGroup)
          msgGroup = {
            sender: msg.sender.id,
            messages: [{
              msg: msg.body,
              time: msg.updated_at,
              sender: msg.sender
            }],
          }
        }
        if (index === chatLog.length - 1) formattedChatLog.push(msgGroup)
      })

      return {
        formattedChatLog,
        contact,
        user,
        profileUserAvatar: props.profileUserAvatar,
      }
    })

    return {
      formattedChatData,
      formatDateToMonthShort,
    }
  },
}
</script>

<style>

</style>
